body {
    background-image: url("background-192112.jpg");
    background-size: 110%;
    font-family: Cantarell, sans-serif;
}

.login-box {
    margin: 0 auto;
    padding: 10px;
    position: relative;
    transition: 1s;
    transform: translateY(50%);
    border-radius: 10px;
    box-shadow: 0 0 2px 1px #34495e;
    background-color: #34495e;
    width: 300px;
    height: 400px;
    text-align: center;
}

.login-input {
    width: auto;
    padding: 7px;
    font-size: 11pt;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #2c3e50;
    text-outline: #95a5a6 1px;
    border: 1px solid black;
    border-radius: 25px;
    outline: none;
    color: #95a5a6;
    transition-duration: .3s;
}

.login-input:focus {
    border-color: #2ecc71;
    box-shadow: 0px 0px 2px 1px #2980b9;
    transition-duration: .3s;
}

.login-input.submit {
    padding: 10px;
    font-size: 12pt;
    font-weight: bold;
    color: white;
    border-color: #2ecc71;
    width: 200px;
    transition: all;
    transition-duration: .3s;
    letter-spacing: 5px;
    text-align: center;

}

.login-input.submit:hover, .login-input.submit:focus {
    transition: all;
    transition-duration: .7s;
    background-color: #2ecc71;

}

.login-h1 {
    color: white;
    text-align: center;

}

.label {
    text-align: left !important;
    border: none;
    background-color: transparent;

}